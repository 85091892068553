.site-footer {
    background: #285A21;
    color: #ffffff;
    padding-top: 80px;
    position: relative;
}
ul.listLink{
    padding-left: 10px;
}
ul.listLink li{
    margin-bottom: 5px;
}
ul.listLink li a{
    font-weight: 400;
    font-size: 14px;
    line-height: 25.06px;
    color: #ffffff;
}
.titleSchool {
    font-size: 44px;
    font-family: "Newsreader";
    font-weight: 500;
    line-height: 52.8px;
}
.titleUniver {
    font-size: 30px;
    font-family: "Newsreader";
    font-weight: 500;
    line-height: 36px;
}
.addressUniver {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 14px;
    line-height: 22.92px;
}
.listOther {
    line-height: 2rem;
    margin-top: 2rem;
}
.copyright{
    font-weight: 400;
    font-size: 12px;
    line-height: 19.64px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid rgb(255, 251, 251, .3);
}
.conUs {
    font-weight: 700;
    font-size: 18px;
    line-height: 29.46px;
    color: #ffffff;
    margin-bottom: 30px;
}
.mediaCon ul li {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
}
@media only screen and (max-width: 767px) {
    .site-footer .titleSchool {
        font-size: 31.19px;
        font-family: "Newsreader";
        font-weight: 500;
        line-height: 37.43px;
        text-align: center;
    }
    .site-footer .titleUniver {
        text-align: center;
        font-size: 22px;
    }
    
    .site-footer .col-md-4 >p{
        text-align: center; 
    }
    .site-footer .mr-5, .mx-5 {
        margin-right: 5rem!important;
        margin-left: 60px;
    }
    .site-footer .col-md-2{
        /* width: 40%; */
        margin-left: 30%;
    }
    .site-footer .conUs {
        
        margin-bottom: 9px;
    }
    .site-footer {
        padding-top: 40px;   
    }
    .site-footer .container {   
        padding-bottom: 48px !important;
    }
    .site-footer ul.listLink {
        margin-top: 32px;
    }
}