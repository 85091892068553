// ** body
// @media (min-width: 1200px) {
//     .container {
//         max-width: 1310px;
//     }
// }
// body {
//     background-color: #e5e5e5 !important;
// }
//
.main-color {
    color: $main-color;
}
.main-bg {
    background-color: $main-bg;
}
.line-color {
    color: $line-color;
}
.line-bg {
    background-color: $line-bg;
}
.bodytext-color {
    color: $bodytext-color;
}
.bodytext-bg {
    background-color: $bodytext-bg;
}
.graytitle-color {
    color: $graytitle-color;
}
.graytitle-bg {
    background-color: $graytitle-bg;
}
.background-color {
    color: $background-color;
}
.background-bg {
    background-color: $background-bg;
}
.subgreen-color {
    color: $subgreen-color;
}
.subgreen-bg {
    background-color: $subgreen-bg;
}

// ** Menu
.dropdown.nav-item {
    border-top: 1px solid $main-color;
}
.dropdown:hover,
.nav-item:hover {
    background-color: #ffffff;
    border-top: 1px solid $line-color;
    .nav-link {
        color: $main-color !important;
    }
    .dropdown-menu {
        display: block ;
        opacity: 1;
        filter: alpha(opacity=100);
        visibility: visible;
    }
}
// End Menu
// BreadCrumb
.breadcrumb {
    padding: 0;
    margin-bottom: 0;
    background-color: transparent;
    .breadcrumb-item {
        font-weight: 700;
        font-size: 16px;
        line-height: 32px;
        text-transform: uppercase;
        color: $bodytext-color;
        &.active {
            font-weight: 400;
            text-transform: unset;
        }
        &::before {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
}
//end breadcrumb
//Custom page

.customPage_content {
    margin-bottom: 160px;
    p,
    span,
    a,
    strong,
    h3,ul {
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 17px;
        line-height: 28.39px;
        strong {
            font-weight: 700;
        }
    }
    p {
        margin-bottom: 1rem;
    }
    h4 {
        margin-bottom: 1rem;
        strong {
            color: #285A21;
        }
    }
    h3 {
        font-weight: 700;
    }
    a {
        span{
            color: #285A21 !important;
            font-weight: 600;
        }
    }
    table {
        a {
            font-size: 14px;
        }
        width: 100%;
    }
    figure.image {
        max-width: 100% !important;
    }
}
#error_6dc93fa6bbde8fa607fd664da691ce389d27ec66{
    display: none !important;
}
.staffbody table{
        
    width: 100% !important;

}
.wp-img{
    text-align: center;
    width: 100%;
}
.wp-img img{
    width:90%;
}
p.p-position{
    margin-bottom: 0px !important;
    margin-top: 8px;
    color: #82a81b;
    font-weight: 600;
    font-style: italic;
    font-size: 15px;
}
p.p-name{
    font-size: 11px;
    font-weight: 500;
}
.customPage_title {
    font-weight: 400;
    font-size: 36px;
    line-height: 54px;
    color: #767676;
    padding: 45px 0 16px 0;
    margin: 0 0 45px 0;
    border-bottom: 1px solid #e5e5e5;
}
.text-subgreen {
    color: $subgreen-color !important;
    font-weight: 700;
}
.d-sidebar {
    display: block !important;
}
.d-sidebar-none {
    display: none !important;
}
.row_title_custom {
    padding: 45px 0 16px 0;
    margin: 0 0 45px 0;
    border-bottom: 1px solid #e5e5e5;
}
.research_title_custom {
    font-weight: 400;
    font-size: 36px;
    line-height: 54px;
    color: #767676;
}
//header
.logo_header {
    width: 265px;
}
.logo_header_mobile {
    display: none;
}
.wp_firstheader {
    padding-left: 19px !important;
    padding-right: 19px !important;
}
//end header
.navbar {
    background-color: $main-bg;
}
.table thead th {
    border-bottom: 2px solid #82a81b;
}
.table td, .table th {
    border-top: 1px solid #82a81b;
}
.table td{
	font-weight: 500;
}
//end custom page
// ==========================Student================================
.listStudentElement {
    display: flex;
    margin-bottom: 1rem;
    .introMedia {
        margin: auto 0;
        padding-left: 1rem;
        font-weight: 600; 
        font-size: 18px;
        color: #0E107E;
     }
}
.student-info{
    margin-top: auto;
    margin-bottom: auto;
}

// ==========================end Student============================
//===========================Media====================================
.wp-media {
    margin-top: 128px;
}
.BULLETIN.col-md-3
{
    background: #1E96F7;
    
    border-radius: 50%;
}

.BULLETIN.col-md-3 > p{
    text-align: center;
}

.BRANDING.col-md-3
{
    background: #80A618;
   
    border-radius: 50%;
}

.BRANDING.col-md-3 > p{
    text-align: center;
}

.IMAGE.col-md-3
{
    background: #285A21;
    
    border-radius: 50%;
}

.IMAGE.col-md-3 > p{
    text-align: center;
}

.VIDEO.col-md-3
{
    background: #A60404;
    
    border-radius: 50%;
}

.VIDEO.col-md-3 > p{
    text-align: center;
}
.media-Intro .contentLabel, .media-Multil .contentLabel{
    font-size: 30px ;
    font-weight: 400;
    color: #767676;
}
.media-Intro .tittleContentLabel {
    margin-top: 61px;
    margin-bottom: 30px;
}
.media-Multil .tittleContentLabel {
    margin-top: 0px;
    margin-bottom: 30px;
}
//photo gallery
.image-gallery-slide .image-gallery-description {
    bottom: 0 !important;
    padding: 30px 20px !important;
    width: 100%;
}
//*** responsive for media ***//
@media (max-width: 767px) {
    .student-info{
        text-align: center;
    }
    .studentMediaimage img{
        margin-right: auto;
        margin-left: auto;
    }
    p.p-position{
        font-size: 10px;
    }
    p.p-name{
        font-size: 7px;
    }
    .wp-img img{
    width: 100%;
    }
    #board .col-3{
        padding-left: 5px;
        padding-right: 5px;
    }
    
    .BULLETIN.col-md-3, .BRANDING.col-md-3, .IMAGE.col-md-3, .VIDEO.col-md-3 {
        
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        max-width: 60%;
        
        height: 15.4rem !important ;
        margin-left: auto;
        margin-right: auto;
    }
    .media-Intro .contentLabel, .media-Multil .contentLabel {
        font-size: 24px;
       
    }
    .me-info.col-md-3 {
        flex: 0 0 85%;
        max-width: 85%;
        margin: auto;
    }
    .BRANDING.col-md-3 > a>img, .BULLETIN.col-md-3 > a>img, .IMAGE.col-md-3 > a>img, .VIDEO.col-md-3 > a>img{
        padding-left: 3.1rem;
        padding-top: 1rem;
        padding-right: 3.1rem;
        padding-bottom: 1rem;
    }
    .me-info.col-md-3{
        text-align: center;
        margin-left: 35px;
        margin-right: 35px;
    }
    .media-Multil , .media-Intro {
        text-align:  center;
    }

    .BRANDING.col-md-3 p, .BULLETIN.col-md-3 p, .IMAGE.col-md-3 p, .VIDEO.col-md-3 p{
        margin-top: 5px !important;
    }
    p.p-border{
        border-bottom: none !important;
        margin-bottom: 100px !important;
    }

}
@media (max-width: 400px) {
    p.p-position{
        font-size: 10px;
    }
    p.p-name{
        font-size: 7px;
    }
    .wp-img img{
    width: 100%;
    }
    #board .col-3{
        padding-left: 5px;
        padding-right: 5px;
    }
    
    .BULLETIN.col-md-3, .BRANDING.col-md-3, .IMAGE.col-md-3, .VIDEO.col-md-3 {
        
        padding-top: 2rem;
        padding-bottom: 2rem;
        max-width: 60%;
        
        height: 14rem !important ;
        margin-left: auto;
        margin-right: auto;
    }
    .media-Intro .contentLabel, .media-Multil .contentLabel {
        font-size: 24px;
       
    }
    .me-info.col-md-3 {
        flex: 0 0 85%;
        max-width: 85%;
        margin: auto;
    }
    .BRANDING.col-md-3 > a>img, .BULLETIN.col-md-3 > a>img, .IMAGE.col-md-3 > a>img, .VIDEO.col-md-3 > a>img{
        padding-left: 2.7rem;
        padding-top: 1rem;
        padding-right: 2.7rem;
        padding-bottom: 0.5rem;
    }
    .me-info.col-md-3{
        text-align: center;
        margin-left: 35px;
        margin-right: 35px;
    }
    .media-Multil , .media-Intro {
        text-align:  center;
    }

    .BRANDING.col-md-3 p, .BULLETIN.col-md-3 p, .IMAGE.col-md-3 p, .VIDEO.col-md-3 p{
        margin-top: 5px !important;
    }
    p.p-border{
        border-bottom: none !important;
        margin-bottom: 100px !important;
    }

}

@media (min-width: 1204px)
 {   
    .BULLETIN.col-md-3 {
        
        max-width: 19%  !important;
        margin-right: 2% !important;
        height: 13.54rem !important;
        padding-top: 2.5rem !important;
        padding-right: 3rem !important;
        padding-bottom: 3rem !important;
        padding-left: 2.65rem !important;
    }
     .BRANDING.col-md-3 {
        
        max-width: 19%  !important;
        margin-right: 2% !important;
        height: 13.54rem !important;
        padding-top: 2.8rem !important;
        padding-right: 3rem !important;
        padding-bottom: 3rem !important;
        padding-left: 2.5rem !important;
    }
    .IMAGE.col-md-3 {
        
        max-width: 19%  !important;
        margin-right: 2% !important;
        height: 13.54rem !important;
        padding-top: 2.8rem !important;
        padding-right: 3rem !important;
        padding-bottom: 3rem !important;
        padding-left: 3rem !important;
    }
    .VIDEO.col-md-3 {
        
        max-width: 19%  !important;
        margin-right: 2% !important;
        height: 13.54rem !important;
        padding-top: 2.8rem !important;
        padding-right: 3rem !important;
        padding-bottom: 3rem !important;
        padding-left: 3rem !important;
    }
    .me-info.col-md-3 {
        flex: 0 0 29% !important;
        max-width: 29% !important;
        margin: auto !important;
    }
    .BRANDING.col-md-3 > a>img, .BULLETIN.col-md-3 > a>img, .IMAGE.col-md-3 > a>img, .VIDEO.col-md-3 > a>img{
        padding-left: 0.6rem !important;
        
    }
    

}
@media (min-width: 768px)
 {   
      .IMAGE.col-md-3, .VIDEO.col-md-3 {
        padding-left: 1.7rem;
        padding-top: 1.5rem;
        max-width: 19%;
        margin-right: 2%;
        height: 11.4rem ;
    }
    .BULLETIN.col-md-3 {
        padding-left: 1.5rem;
        padding-top: 1.3rem;
        max-width: 19%;
        margin-right: 2%;
        height: 11.4rem ;
    }
    .BRANDING.col-md-3 {
        padding-left: 1.4rem;
        padding-top: 1.3rem;
        max-width: 19%;
        margin-right: 2%;
        height: 11.4rem ;
    }
    .me-info.col-md-3 {
        flex: 0 0 29%;
        max-width: 29%;
        margin: auto;
    }
    .BRANDING.col-md-3 > a>img, .BULLETIN.col-md-3 > a>img, .IMAGE.col-md-3 > a>img, .VIDEO.col-md-3 > a>img{
        padding-left: 1rem;
        
        
    }

}
//===========================End media=================================
//======LECTURER===
.wp-lecturer {
    margin-bottom: 160px;
    margin-top: 128px;
    p,
    span,
    a,
    strong,
    h3,ul {
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 16px;
        line-height: 28.39px;
        strong {
            font-weight: 700;
            font-size: 20px;
        }
    }
    p {
        margin-bottom: 1rem;
    }
    h4 {
        margin-bottom: 1rem;
        strong {
            color: #285A21;
        }
    }
    h3 {
        font-weight: 700;
    }
    a {
        span{
            color: #285A21 !important;
            font-weight: 600;
        }
    }
    table {
        a {
            font-size: 14px;
        }
    }
    figure.image {
        max-width: 100% !important;
    }
}
.wp-lecturer h4 span:after {
    background: none repeat scroll 0 0 rgb(190, 15, 15);
    bottom: -10px;
    content: "";
    display: block;
    height: 3px;
    position: relative;
    width: 30px;
    margin-bottom: 20px;
}
.wp-lecturer .col-md-8{
    padding-left: 25px;
}

