.Research{
    height: 560px;
    display: flex;
}
.research-title{
    margin: 120px 0 7px;
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
}

a.viewAllCollection{
    font-size: 14px;
    line-height: 26px;
    font-weight: 700;
    color: #fff;
    text-decoration: underline;
}
.infoCollection a {
    text-decoration: none;
    font-style: italic;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
}
.wp-academic a:hover {
    
    text-decoration: underline;
}



@media only screen and (max-width: 600px) {
    
    .wp-academic.container .research-title{
        margin-left: 32px;
    }
    .wp-academic.container{
        padding: 0px;
    }
    
}
  
