.news-block {
    float: left;
    width: 95%;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 20px;
    min-height: 220px;
}
.news-block .left-img {
    width: 165px;
    height: 115px;
    margin-top: 25px;
    position: relative;
    overflow: hidden;
    float: left;
}
.news-block .left-img img {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: auto;
    max-height: 100%;
    max-width: none;
    transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    vertical-align: middle;
}
.news-block .content {
    padding-top: 20px;
    padding-left: 190px;
}
.news-block .content .news-title {
    color: #285A21;
    font-size: 20px;
    font-weight: 500;
}
.news-block .content .news-date {
    color: #000;
    font-size: 15px;
    font-style: italic;
    padding: 10px 0;
}
.news-summary {
    color: black;
    margin-bottom: 3%;
}
.news-summary {
    color: black;
    margin-bottom: 3%;
    font-weight: 400;
}
.news-all {
    float: left;
    width: 100%;
    display: block;
    border-right: 1px solid #e5e5e5;
}
    
   .pagination {
    padding-top: 1rem;
    margin-left: -1px;
    display: flex;
    list-style: none;
    outline: none;
   }
    
   .pagination>.active>a {
    background-color: #47ccde;
    color: #fff;
   }
    
   .pagination>li>a {
    border: 1px solid #47ccde;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
   }
    
   .pagination>li>a, .pagination>li>span {
    color: #47ccde;
    background-color: azure;
   }

.right-box-research-other p, .right-box-research-other a  {
    font-family: "Montserrat", "Montserrat", "Helvetica", Montserrat, sans-serif;
    font-size: 17px ;
    font-weight: 400 ;
    letter-spacing: 0em ;
    line-height: 1.87 ;
    display: initial ;
    text-align: justify;
}
.main-app ul li strong {
    font-weight: 600;
}
.main-app ul li a {
    font-weight: 500 !important;
}