.journal-header>a>h3 {
    font-family: "Montserrat", "Montserrat", "Helvetica", Montserrat, sans-serif;
    font-weight: bold;
    color: #000;
    padding-bottom: 2rem;
    padding: 0 0 2rem 1rem;
    border-bottom: 4px solid #000;
}

.journal-content {
    margin-top: 2rem;
    padding: 0 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    line-height: 1.6;
    height: 8.5em;
}

.journal-content>a {
    font-weight: 600;
    color: #000;
}

.journal-content>a>span {
    color: #7f7f7f;
    font-size: 1rem;
}

.journal-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: 1.6;
    height: 5em;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
}

/* topic About LQDTU */

.topic {
    border: 1px solid #B2802B;
    height: 360px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.topic_header {
    height: 70px;
    background-color: #B2802B;
    display: grid;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.topic_header>span {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #ffffff;
    margin: auto;
}

.topic_content {
    padding: 36px 28px 55px 26px;
}

.topic_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
    color: #000000;
}

.topic .topic_title {
    height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.topic_description {
    height: 125px;
    font-weight: 400;
    font-size: 14px;
    line-height: 26.19px;
    margin-top: 18px;
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.topic_title_announce {
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
    color: #000000;
    height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.topic_description_announce {
    height: 50px;
    font-weight: 400;
    font-size: 16px;
    line-height: 26.19px;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.announcement_header {
    display: grid;
    height: 74px;
    border-bottom: 1px solid #E5E5E5;
}

.announcement_header>span {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #285A21;
    margin: auto 0;
}

/* Upcoming Events */

.announcement {
    position: relative;
    background-color: #ffffff;
}

.announcement .post-item {
    padding: 18px 0px 28px 0px;
}

.announcement .event-caption {
    display: flex;
}

.announcement .left-box {
    min-width: 30px;
}

.announcement .right-box {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.cat-shape-journal {
    background: #285A21;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-top: 10px;
}

.currentIssue {
    text-align: left;
    font-weight: 700;
    font-size: 16px;
    line-height: 26.19px;
    text-decoration: underline;
}

.about_link_journal:hover {
    text-decoration: none !important;
}

/* responsive */

@media only screen and (max-width: 1180px) {
    .topic {
        height: 298.58px;
    }
    .topic_header {
        height: 56px;
    }
    .topic_header>span {
        font-size: 18px;
    }
    .topic_content {
        padding: 14px 16px 34px 16px;
    }
    .topic_description {
        margin-top: 0;
    }
}

@media only screen and (max-width: 960px) {
    .topic {
        height: 213.28px;
    }
    .topic_header {
        height: 39px;
    }
    .topic_header>span {
        font-size: 14px;
    }
    .topic_content {
        padding: 11px 12px 25px 9px;
    }
    .topic_title {
        height: 52px;
        -webkit-line-clamp: 2;
        font-size: 12px;
    }
    .topic_description {
        margin-top: 0;
        height: 77px;
        -webkit-line-clamp: 3;
        font-size: 12px;
    }
}

@media only screen and (max-width: 767px) {
    .topic {
        height: 341.24px;
    }
    .topic_header {
        height: 64px;
    }
    .topic_header>span {
        font-size: 20px;
    }
    .topic_content {
        padding: 14px 16px 34px 16px;
    }
    .topic_title {
        font-size: 14px;
    }
    .topic_description {
        margin-top: 0;
        height: 130px;
        -webkit-line-clamp: 5;
        font-size: 14px;
    }
    .journal {
        margin-left: 32px;
        margin-right: 32px;
    }
}