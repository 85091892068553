.Research{
    height: 560px;
    display: flex;
}
.research-title{
    margin: 120px 0 7px;
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
}
.research-lable{
    background-color: #285A21;
    width: 285px;
    padding: 38px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.research-lable h3{
    text-align: center;
    font-weight: 600;
    font-size: 38px;
    color: #fff;
    flex-grow: 1;
}
.reseach-body{
    flex-grow: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.research-item{
    position: relative;
    width: 100%;
    height: 100%;
}
.research-item img {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
}
.research-content{
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    padding: 38px 43px;
    display: flex;
    flex-direction: column;
}
.research-content h4{
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #fff;
    margin-bottom: 19px;
    margin-top: 0;
}
a.viewAllCollection{
    font-size: 14px;
    line-height: 26px;
    font-weight: 700;
    color: #fff;
    text-decoration: underline;
}
.infoCollection a {
    text-decoration: none;
    font-style: italic;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
}
.wp-research a:hover {
    
    text-decoration: underline;
}
.ISI-item{
    background-color: rgba(127, 161, 33, 0.95);
}
.ISI-body{
    flex-grow: 1;
}
.ISI-body ul{
    height: 50%;
    padding: 0 0 0 15px;
}
.ISI-body ul:first-child {
    border-bottom: #fff solid 1px;
    margin-bottom: 30px;
    padding-bottom: 30px;
}
.ISI-body ul li {
    color: #fff;
}
.ISI-body ul li>.infoCollection{
    height: 153px;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #fff;
    white-space: pre-wrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}
.ISI-body ul li>.infoCollection p{
    display: contents;
}
.IPC-item{
    background-color: rgb(69,147,211, 0.95);
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 960px) {
  .Research{
      flex-direction: column;
      height: auto;
  }
  .research-lable{
      width: 100%;
  }
  .research-lable h3{
      font-size: 28px;
      margin-bottom: 4px;
  }
  .research-logo{
      height: 84px;
      width: 100px;
      margin-top: 20px;
    
  }
  .research-item {
      height: 589px;
  }
}
@media only screen and (max-width: 600px) {
    .research-title{
        font-size: 24px;
        margin-top: 60px;
    }
    .research-item {
        height: 589px;
    }
    .reseach-body {
        grid-template-columns: 1fr;
    }
    .research-content {
        padding: 46px 32px;
    }
    .ISI-body ul li p{ 
        height: 175px;
        -webkit-line-clamp: 7;
    }
    .SlideResearch .slick-slide {       
        height: auto;
    }
    .wp-research.container .research-title{
        margin-left: 32px;
    }
    .wp-research.container{
        padding: 0px;
    }
    .SlideResearch .slick-next {
        right: -25px;
    }
    .SlideResearch .slick-prev {
        left: -25px;
    }
}
  
