.l-five {
    width: 100%;
}
.image-with-hover-overlay img {
    width: 100%;
}
#contentlabelRes .s-1, .s-2, .s-five, .s-3, .s-4, .s-5, .s-6, .s-7, .s-8, .s-9, .s-10, .s-11, .s-12, .m-1, .m-2, .m-five, .m-3, .m-4, .m-5, .m-6, .m-7, .m-8, .m-9, .m-10, .m-11, .m-12, .l-1, .l-2, .l-five, .l-3, .l-4, .l-5, .l-6, .l-7, .l-8, .l-9, .l-10, .l-11, .l-12, .xl-1, .xl-2, .xl-five, .xl-3, .xl-4, .xl-5, .xl-6, .xl-7, .xl-8, .xl-9, .xl-10, .xl-11, .xl-12, .xxl-1, .xxl-2, .xxl-five, .xxl-3, .xxl-4, .xxl-5, .xxl-6, .xxl-7, .xxl-8, .xxl-9, .xxl-10, .xxl-11, .xxl-12 {
    float: left;
    position: static;
}
#contentlabelRes {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
} 
/* div {
    display: block;
} */
.full-width:after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    line-height: 0;
    visibility: hidden;
}
#contentlabelRes img {
    border: 0;
    display: block;
    height: 15rem;
    max-width: 100%;
    width: 100%;
} 
.tm-catalog-item-description {
    
    flex: 1;
    height: 15rem;
}
.tm-bg-green {
    background-color: #285A21 !important;
    font: Montserrat;
    color: #FFFFFF !important;
}
.tm-bg-green  a{
    color: #FFFFFF !important;
}
.tm-bg-white {
    background-color: #F5F5F5 !important;
    color: #161616 !important;
}
.tm-bg-white a{
    color: #161616 !important;
}

#contentlabelRes .slick-slide > div {
    width: 100%;
} 
.arrow-up {
    width: 20px;
    height: 25px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid #F5F5F5;
    position: absolute;
    /* margin-left: 7%; */
    left: 47%;
    top: 0;
    margin-top: -25px;
  }
  .arrow-down {
    width: 20px;
    height: 25px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 20px solid #285A21;
    position: absolute;
    left: 47%;
    bottom: 0;
    margin-bottom: -25px;
  }
  #contentlabelRes .slick-next {
    right: 3.1% !important;
    z-index: 1;
    color: white;
    top: 47%;
} 
#contentlabelRes .slick-prev {
    left: 2% !important;
    z-index: 1;
    top: 47%;
}
@media only screen and (max-width: 960px) {
    #contentlabelRes .slick-prev {
        left: 2.3% !important;
        z-index: 1;
        top: 48%;
    }
    #contentlabelRes .slick-next {
        right: 2.3% !important;
        z-index: 1;
        color: white;
        top: 48%;
    } 
}
@media only screen and (max-width: 1280px) {
    #contentlabelRes .slick-prev {
        left: 2.3% ;
        z-index: 1;
        top: 48%;
    }
    #contentlabelRes .slick-next {
        right: 2.3% ;
        z-index: 1;
        color: white;
        top: 48%;
    } 
    .navbar-nav .nav-item .nav-link {
          padding: 19px 8px ;
          line-height: 26.19px;
          
        }

}
@media only screen and (max-width: 767px) {
    #contentlabelRes .tm-catalog-item-description {
        
        height: 12.4rem ;
    }
    #contentlabelRes img {
        
        height: 18.8rem ;
    }
    
    .researchGroup {
        margin-left: 32px ;
        margin-right: 32px ;
    }
    
}
@media only screen and (max-width: 600px) {
    #contentlabelRes .slick-prev {
        left: 2.3%;
        z-index: 1;
        top: 48%;
    }
    
    #contentlabelRes .slick-next {
        right: 2.3% ;
        z-index: 1;
        color: white;
        top: 48%;
    } 
}
@media only screen and (max-width: 480px) {
    #contentlabelRes .slick-prev {
        left: -4.7% ;
        z-index: 1;
        top: 50%;
    }
    #contentlabelRes .slick-next {
        right: -8.7% ;
        z-index: 1;
        color: white;
        top: 50%;
    } 
}
#contentlabelRes  .slick-next:before .slick-prev:before {
    
    line-height: 1;
    
    color: #161616;
}
#contentlabelRes   .slick-prev:before {
    margin-left: -15px;
    line-height: 1;
    
    color: #161616;
}
#contentlabelRes .slick-next:before {
    margin-left: -15px;
    line-height: 1 !important;
    
    color: #161616;
}
/* #contentlabelRes .slick-next.slick-disabled:before, .slick-prev.slick-disabled:before {
    opacity: 1;
}  */
.tm-catalog-item-text{
    font-size: 17px !important;
    line-height: 1.5em !important;
    margin-bottom: 5px !important;
    font-weight: 600 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    height: 120px;
}
#contentlabelRes .p-4 {
    padding: 2rem !important;
} 
#contentlabelRes > .tittleContentLabel{
    margin-left: 115px;
}
#contentlabelRes .slick-slide > div {
    margin-left: 0px;
} 
#contentlabelRes{
    margin-bottom: 108px;
}

