.partnerPage {
    margin-bottom: 60px;
}
.partner-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
    margin-bottom: 30px;
}
.grow {
    flex-grow: 1;
}
.partner-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 200px;
    border-bottom: #e5e5e5 solid 1px;
    padding-bottom: 10px;
}
.partner-item-img{
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.partner-item img {
    /* width: 80%; */
    max-width: 80%;
    max-height: 100%;
}
.partner-item a{
    color: #82a81b ;
    /* font-size: 16px; */
    text-decoration: none;
    text-align: center;
    font-weight: 500;
}
.partner-item a:hover{
    text-decoration: underline;
}