/* Style cho slide */

.SlideResearch {
    height: 500px;
    display: block;
}

/* Style cho slide Item */

.SlideResearch-item {
    padding-left: 15px;
    padding-right: 15px;
    
}

.SlideResearch-item img {
    width: 100%;
    height: 360px;
   
}

.SlideResearchContainer {
    position: relative;
    margin-bottom: 27px;
}

.SlideResearchContainer:hover .overlaySlideResearch {
    opacity: 0.8;
    cursor: pointer;
}

.overlaySlideResearch {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 1s ease;
    background-color: #161616;
}

.textSlideResearch {
    font-weight: 400;
    font-size: 18px;
    line-height: 28.8px;
    color: #ffffff;
    
    position: absolute;
    max-height: 80%;
    width: 80%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.SlideResearch-item a {
    font-weight: 700;
    color: #285A21;
    font-size: 21px;
    /* white-space: pre-wrap;  */
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

/* style button slide */

.slick-next:before, .slick-prev:before {
    font-size: 64px;
    color: #161616;
    opacity: 0.8 !important;
}

/* .slick-prev:before{
    margin-left: -60px;
} */

.slick-prev, .slick-next {
    top: 36%
}

/* responsive */

@media only screen and (max-width: 1280px) {
    .slick-next:before, .slick-prev:before {
        font-size: 50px;
    }
}

@media only screen and (max-width: 960px) {
    .slick-next:before, .slick-prev:before {
        font-size: 48px;
    }
}

@media only screen and (max-width: 600px) {
    .SlideResearch {
        height: 315px;
        margin-left: 29px !important;
        margin-right: 29px !important;
    }
    .SlideResearch-item img {
        height: 202px;
    }
    .slick-next:before {
        margin-left: 7px;
    }
    .slick-prev:before {
        margin-left: -10px;
    }
    .SlideResearch-item a {
        font-size: 18px
    }
}

@media only screen and (max-width: 480px) {
    .SlideResearch {
        height: 280px;
    }
    .SlideResearch-item img {
        height: 180px;
    }
    .SlideResearchContainer {
        margin-bottom: 16px;
    }
    .slick-next:before, .slick-prev:before {
        font-size: 32px;
    }
    .slick-prev:before {
        margin-left: -7px;
    }
}